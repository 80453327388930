import React from "react"
import classNames from "classnames"
import showdown from "showdown"
import PropTypes from "prop-types"
import ImageMeta from "../../components/ImageMeta"

function createHtml(html) {
  return { __html: html }
}
const converter = new showdown.Converter()

function TeamMember(props) {
  return (
    <div
      className="column is-4"
      key={props.publicId}
      style={{ padding: "0 5px" }}>
      <ImageMeta
        cloudName="nuvolum"
        publicId={props.publicId}
        responsive
        width="auto"
      />
      <div className="has-text-centered team-member-item">
        <h4 className="" style={{ margin: 0, marginBottom: "0" }}>
          {props.staffName}
        </h4>
        <p className="">{props.staffTitle}</p>
      </div>
      {/* <h6 className="image-caption staff staff-name">{props.staffName}</h6>
      <h6 className="image-caption staff staff-title light">
        {props.staffTitle}
      </h6> */}
    </div>
  )
}

TeamMember.propTypes = {
  publicId: PropTypes.string,
  staffName: PropTypes.string,
  staffTitle: PropTypes.string
}

function TeamRow(props) {
  const sideColumns = classNames({
    "is-2": props.members.length >= 4
  })
  return (
    <div className={`columns staff-row ${props.last ? "last" : ""}`}>
      <div className={`column ${sideColumns}`} />
      {props.members.map(member => (
        <TeamMember
          key={member.staffMember.imageId}
          publicId={member.staffMember.imageId}
          staffName={member.staffMember.staffName}
          staffTitle={member.staffMember.staffTitle}
        />
      ))}
      <div className={`column ${sideColumns}`} />
    </div>
  )
}

TeamRow.propTypes = {
  members: PropTypes.array
}

function MeetOurTeam(props) {
  if (!props.post.meetOurTeam.hasThisSection) return <></>

  const meetOurTeamClasses = classNames({
    "body-section meet-our-team": true,
    "color-back": props.invert
  })

  const members = props.post.meetOurTeam.staffImages

  const memberRows = []
  const size = 3

  for (let i = 0; i < members.length; i += size) {
    memberRows.push(members.slice(i, i + size))
  }

  return (
    <section className={meetOurTeamClasses}>
      <div className="columns meet-our-team-heading">
        <div className="column is-4" />
        <div
          className="column mobile-col has-text-centered-tablet"
          dangerouslySetInnerHTML={createHtml(
            converter.makeHtml(props.post.meetOurTeam.text)
          )}
        />
        <div className="column is-4" />
      </div>

      {props.post.meetOurTeam.showStaff && (
        <>
          {memberRows.map((row, i) => (
            <React.Fragment key={i}>
              <TeamRow
                listKey={i}
                members={row}
                last={i === memberRows.length - 1}
              />
            </React.Fragment>
          ))}
        </>
      )}
    </section>
  )
}

MeetOurTeam.propTypes = {
  invert: PropTypes.bool,
  post: PropTypes.shape({
    meetOurTeam: PropTypes.shape({
      text: PropTypes.string,
      staffImages: PropTypes.array
    }).isRequired
  }).isRequired
}

export default MeetOurTeam
