export const button = {
  label: "Button",
  name: "button",
  widget: "object",
  required: false,
  fields: [
    {
      label: "buttonText",
      name: "buttonText",
      widget: "string",
      required: false,
      hint: "Not needed for Watch Video buttons"
    },
    {
      label: "Button URL",
      name: "href",
      widget: "string",
      required: false,
      hint: "Not needed for Watch Video buttons. Start and end with backslash. For telephone, format like so: +16192875000. For anchor buttons, please include the full URL with #id at the end, and do not have a slash at the end."
    },
    {
      label: "destination",
      name: "destination",
      widget: "select",
      options: [
        "internal",
        "external",
        "download",
        "tel",
        "anchor",
        "samePageAnchor"
      ],
      default: "internal",
      required: false,
      hint: "Not needed for Watch Video buttons"
    },
    {
      label: "Button Appearance",
      name: "appearance",
      widget: "select",
      options: ["regular", "contained", "minimal", "video"],
      default: "contained",
      required: false
    },
    {
      label: "Youtube Embed",
      name: "youtube",
      widget: "theaterYoutube",
      required: false
    }
  ]
}
