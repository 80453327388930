import React from "react"
import classNames from "classnames"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"

function LearnMoreCTA(props) {
  const wrapperClassNames = classNames(
    "body-sections",
    "section",
    "learn-more-section",
    props.className,
    {
      "color-back": props.colorBack
    }
  )

  return (
    <section className={wrapperClassNames}>
      <div className="columns has-text-centered-tablet">
        <div className="column is-5" />
        <div className="column">
          <h3 style={{ marginBottom: "1.5rem" }}>
            {props.post.learnMore.heading}
          </h3>
        </div>
        <div className="column is-5" />
      </div>
      <div className="columns has-text-centered-tablet">
        <div className="column is-4" />
        <div className="column mobile-col">
          <MarkdownViewer markdown={props.post.learnMore.blurb} />
        </div>
        <div className="column is-4" />
      </div>

      <div className="columns">
        <div className="column">
          <ButtonGroupMap
            isCentered
            noReverse
            buttons={props.post.learnMore.buttons}
          />
        </div>
      </div>
    </section>
  )
}

export default LearnMoreCTA
