import React from "react"

function createHtml(html) {
  return { __html: html }
}
const showdown = require("showdown")

const converter = new showdown.Converter()

function OurMission(props) {
  return (
    <>
      <div className="our-mission__color-wall" />
      <section className="our-mission">
        <div
          style={{ width: "100%" }}
          className="columns has-text-centered-tablet">
          <div className={`column is-${props.sideColumnIs}`} />
          <div
            className="column"
            dangerouslySetInnerHTML={createHtml(
              converter.makeHtml(props.post.ourMission)
            )}
          />
          <div className={`column is-${props.sideColumnIs}`} />
        </div>
      </section>
    </>
  )
}

export default OurMission
